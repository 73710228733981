.picture_width{
    width:400px;
    height:200px;
    background:unset;
    background-color: #242424
}
.header-container-fluid{
    background-color: var(--header-bg);
    /* background-color: burlywood; */
    min-height: 85px;
    
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
    align-content: center;

    position: fixed;
    width: 100%;
    top: 0;
    padding: 0 15px;
    z-index: 999;
}
.header-brand a {
    color: var(--header-branding-color);
    font-family: "Palanquin Dark", sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
}
.header-links {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
}
.header-links .links {
    width: 250px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}
.header-links .links a {
    font-family: "Palanquin Dark", sans-serif;
    color: var(--header-link-color);
    text-decoration: none;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    transition: 0.2s;
}
.header-links .links a:hover{
    color: var(--header-link-hover-color);
}
@media only screen and (max-width: 600px) {
    .picture_width{
        width:290px;
        height:300px;
    }
}