@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
.root-privacy {
  background-color: black;
  color: white;
  font-family: "Poppins";
}
.root-privacy span {
  display: block;
  margin-top: 28px;
}
.metallic-background-privacy {
  background: url("../../assets/images/landing/metallicBlackBackground.png")
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.mini-head {
  font-weight: bold;
}
.policy-header {
  height: 113px;
  font-size: 48px;
  font-weight: 700;
}
h2 {
  color: white;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 2.5px;
}
.policy-header h2 {
  font-weight: 700;
}
h3 {
  color: white;
  font-size: 22px;
  line-height: 1.5em;
  letter-spacing: 2.5px;
}
p {
  text-align: left;
  color: white;
  font-size: 16px;
  /*font-weight: 100;*/
  line-height: 1.6em;
}

table,
th,
td {
  border: 1px solid white;
  padding: 5px;
}
th {
  color: white;
}
.dot {
  margin-left: 10px;
  margin-bottom: 1.6em;
}
li {
}
@media only screen and (max-width: 600px) {
  .policy-header{
    height: 50px;
  }
  
 }