.creator-menu {
    height: 60px;
    padding: 15px 20px;
    background-color: black;
}
.creator-menu img {
    position: absolute;
    height: 35px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.creator-menu a {
    float: right;
    text-decoration: none;
    color: white;
    background: #067ED5;
    border-radius: 4px;
    padding: 5px;
    width:100px;
    text-align: center;
}
