/*
    Landing Main Styles
*/

/* VARIABLE DEFINITIONS */
:root {
  --primary: #030303;
  --secondary: #f2f2f2;
  --accent: #f2f2f2;
  --primary-fg: #ffffff;

  --body-fg: #000000;
  --body-bg: #ffffff;
  --body-quiet-color: #1d1c1d;

  --header-color: var(--link-fg);
  --header-branding-color: var(--link-fg);
  --header-bg: #ffffff;
  --header-link-color: var(--link-fg);
  --header-link-hover-color: #9f9f9f;

  --footer-color: rgba(255,255,255,0.32);
  --footer-branding-color: rgba(255,255,255,0.32);
  --footer-bg: #2d2d2d;
  --footer-link-color: rgba(255,255,255,0.32);
  --footer-link-hover-color: #04bd1c;

  --link-fg: #060606;
  --link-hover-color: #090909;
  --link-selected-fg: #090909;
}