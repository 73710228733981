@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
.login_body{
    /*height:100vh;*/
    width:100vw;
    display:flex;
    flex-direction:column;
    justify-content:center ;
    align-items:center;
    overflow:hidden;
}

.h1__signup{
    margin-top:20%;
    font-family: DM Sans , sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 31px;

    /* identical to box height, or 103% */
    display: flex;
    align-items: center;
    text-align: center;

    color: #27273B;
}
.p__signup , .p__signup a{

    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    /* identical to box height, or 150% */
    display: flex;
    align-items: center;

    color: #60637F;
}
.p__signup a{
    text-decoration: none;
    color: #02137E;
}
.costum_div{
    width:100vw;
    display:flex;
    flex-direction:column;
    justify-content:center ;
    align-items:center;
    margin-bottom: 39px;
}
.label__signup{
    width:90%;

    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    justify-content:start;

    color: #60637F;
}
.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: #e9e9e9;
    border-radius: 6px;
    vertical-align: middle;

    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin-right: 3px;
}
.font_size{
    font-size: 14px;
    color:gray;
}
.checkbox-round:checked {
    background-color: gray;
}
.checkbox-round--modifer{
    width:90%;
    display: flex;
    justify-content:start;
    margin-top: 104px;
}
.font_size--label__signup{

    font-family: Inter,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    width:80%;
    padding-left: 5px;
    /* or 158% */
    display: flex;
    align-items: center;

    color: #60637F;
}
.button__extra--login{
    width:90%;
    border-radius: 10px;
}
