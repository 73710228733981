.choose_wrapper {
    background-color: black;
    width:100vw;
    height:100vh;
    max-width: 500px;
    position:absolute;
    text-align: -webkit-center;
    overflow: hidden;
}
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap');
.title_choose{
    width: 100%;
    padding: 1vh 0;
    display:flex;
    justify-content:center ;
    align-items:center;
    font-family: DM Sans , Inter ,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: white;
}
.background {
    position:absolute;
    width: 100%;
    height: auto;
}
.miniis {
    max-width: 500px;
}
.box__choose{
    z-index: 5;
    display:flex;
    justify-content:center;
    align-items:center;
}
.active--box__choose{
    background-color: #40375a;
}
.box__choose img {
    width: 90%;
    height: auto;
}
.choose_woman img{
    float: left;
}
.choose_man img{
    float: right;
}
.last_choose{
    padding-top:5vh;
    font-family: DM Sans ,sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5vh;
    letter-spacing: 0em;
    text-align: center;
    width:100%;
    display: flex;
    align-items: center;
    justify-content:center ;
    color: white;
}

.button__extra--login{
    margin: auto;
    width:90%;
    border-radius: 10px;
}

.line-container {
    justify-content: center ;
    display:flex;
    width: 100%;
    padding-top: 5vh;
}
.line {
    position:absolute;
    width: 70vw;
    height: 0px;
    border: 1px solid rgba(208, 208, 208, 0.5);
    border-bottom: 0px;
}
.number-loading {
    text-align: center;
    color: rgb(0, 0, 255);
    font-weight: bolder;
    font-family: "Poppins";
    font-size: 48px;
    width: auto;
    padding: 0;
    line-height: 63.68px;
    height: 65px;
}
.loading-preview {
    padding-top: 5vh;
}
