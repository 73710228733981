@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@font-face {
  font-family: "LCD";
  src: url("../../assets/fonts/lcd-bold.ttf"); /* IE9 Compat Modes */
}
.with-text{
  width: 843px
}
.increase_image{
  max-width:3rem;
}
.image_text_flex{
  display: flex;
justify-content: end;
margin-right: 29px;
}
.popup_text_title{
  font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 64px;
color: #FAFAFA;
}
.popup_text_main{
  font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
color: #FAFAFA;
text-align: center;
width: 70%;
}
.body-root {
  background: black;
  font-family: poppins;
  font-size: 16px;
}
.top-time-remaining {
  font-weight: bold;
  font-size: 24px;
  font-family: "Poppins";
}
.bracket-title {
  padding: 0;
}
.bg-dark {
  background-color: black !important;
  height: 111px;
}
.white-color {
  color: white;
}
.blue-color {
  color: #067ed5;
}
.badgi-images {
  width: 75px;
  height: 65px;
}
.quest-text {
  width: 816px;
}
.top-create-btn-image {
  max-width: 85%;
  padding-top:50px;
}
.container-fluid {
  margin: 0;
  padding: 0;
}
.nav-item-btn {
  font-weight: 700;
}
.nav-item-btn:hover {
  cursor: pointer;
}
li {
  margin-bottom: 0 !important;
}
.navbar-brand {
  margin-right: 0;
}
.gominii-logo {
  width: 176px;
  height: 49px;
  margin-left: 48px;
}

.navbar-collapse {
  top: 30px !important;
  position: relative;
}
/* .navbar-expand-sm .navbar-collapse {
  justify-content: end;
  position: initial;
} */
.navbar-expand-lg .navbar-collapse {
  justify-content: end;
  position: initial;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  font-family: "Poppins";
  font-size: 16px;
}
.nav-link span {
  display: block;
  text-align: center;
  font-size: 16px;
}
.nav-link > span {
  margin-top: 3px;
}
.nav-link {
  font-size: 0.9rem;
}
.nav-item {
  margin: 0.5rem 1.2rem 0.5rem 2rem;
  transition: all 0.2s ease-in-out;
}
.nav-lg-btn {
  width: 207px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
  line-height: 32px;
}
.nav-lg-btn > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 143px;
}
.nav-item img:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.nav-item-regular {
  margin-top: 0.2rem;
}
.drop-logo {
  width: 77px;
  height: 34px;
  margin-top: 0.5rem;
}

.nav-btn {
  color: white;
  border-radius: 5px;
  background-color: #067ed5;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.9rem;
}

.nav-btn-sm {
  color: white;
  border-radius: 5px;
  background-color: #067ed5;
  padding-left: 9px;
  padding-right: 9px;
  font-size: 12px;
  margin-right: 10px;
  font-weight: 500;
}
.top-miniis {
  margin-left: -40px;
  margin-top: 35px;
  margin-bottom: -30px;
}
.yellow-mutants {
  position: absolute;
  color: #fba90a;
  top: 60%;
  left: 30%;
  width: 250px;
  height: 145px;
  transform: rotate(-8deg);
}
.yellow-mutants img {
  width: 230px;
  height: 145px;
}
.navbar-dark .navbar-toggler {
  margin-left: 10px;
}
.discord-link:hover {
  cursor: pointer;
}
.metallic-background {
  background: url("../../assets/images/landing/metallicBlackBackground.png")
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.metallic-background-toggle-reverse {
  background: url("../../assets/images/landing/metallicBlackBackground.png")
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.metallic-background-toggle {
  background-color: black;
}
.bold-big-text {
  font-weight: bold;
  color: white;
  font-size: 48px;
  line-height: 64px;
  padding-left: 0;
  /* text-align: justify; */
}
.minii-image-text {
  height: 3rem;
  margin-right: 10px;
  margin-left: 10px;
}
.center-text {
  text-align: center;
  width: 100%;
}
.middle-image-explanation {
  font-size: 16px;
  line-height: 28px;
  width: 347px;
}
.strong-text {
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  font-size: 24px;
}
.number-blue-border {
  text-align: center;
  color: white;
  font-weight: bolder;
  border: 4px solid #067ed5;
  border-radius: 7px;
  font-family: "Poppins";
  font-size: 48px;
  width: auto;
  padding: 0;
  line-height: 63.68px;
  height: 65px;
  width: 54px;
}
.timer-number {
  font-family: "LCD";
}

.l-r-padding {
  padding-left: 2rem;
  padding-right: 2rem;
}
.l-padding {
  padding-left: 2rem;
}

.r-padding {
  padding-left: 2rem;
}
.exp-text {
  font-family: roboto;
  display: block;
  font-size: 16px;
  /* text-align: justify; */
  color: #fafafa;
  font-family: "Roboto";
  padding: 0;
  line-height: 28px;
}

.text-btn-blue {
  color: #067ed5 !important;
}
.just-roboto {
  font-family: roboto;
}
.auto-mt-mb {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}
.bottom-list-style ul {
  list-style: none;
  text-align: left;
  padding-left: 0;
}
.bottom-list-style ul li {
  text-align: left;
  padding-left: 0;
  font-size: 12px;
  margin-top: 0.3rem;
  font-size: 12px;
  font-family: "Poppins";
}
.bottom-list-style ul li a:hover {
  color: gray !important;
  cursor: pointer;
}
.footer-exp-text {
  font-size: 12px;
  line-height: 24px;
  font-family: "Poppins";
}
.footer-link {
  text-decoration: none;
  color: white;
  font-size: 16px;
  line-height: 24px;
}
.footer-link:hover {
  cursor: pointer;
  color: gray;
}
.second-row-images {
  height: 380px;
}
.dcoin-img-footer {
  width: 115px;
  height: 90px;
}

.dcoin-img-timer {
  max-width: 18rem;
}
.subscribe-text {
}
.news-letter-input {
  width: 100%;
}
.subscribe-btn-container {
  justify-content: start !important;
  margin-top: 0 !important;
}
.discord-middle-image {
  width: 45px;
}
.phones-image {
  padding: 4rem;
  width: 521px;
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 768px) {

  .test_test{
    margin-top: 2px;
  }
  .test_test_test{
    /* position: absolute; */
    top: 2px;
  }

  .test_test1{
    position: relative; 
    /* bottom: 113px;*/
  }

  .metallic-background {
    background: url("../../assets/images/landing/metallicSm.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-clip: content-box;
  }
  .top-time-remaining {
    font-weight: bold;
    font-size: 24px;
    font-family: "Poppins";
  }
  .presale-nft-text {
    font-weight: bold;
    font-size: 19px;
    font-family: "Poppins";
  }
  .quest-text {
    width: 346px;
  }
  .phones-image {
    width: 283px;
    padding: 0;
  }
  .increase_image{
    max-width:4rem;
  }
  .navbar-nav {
    position: absolute;
    background: black;
    min-width: 50%;
    padding-bottom: 10px;
    text-align: center;
  }
  .yellow-mutants img {
    width: 120px;
    height: 75px;
  }
  .center-text {
    text-align: center;
    width: 100%;
  }
  .middle-image-explanation {
    font-size: 12px;
    line-height: 21px;
    width: 321px;
  
  }
  .mie_fix{
    height: 239px
  }
  .mie_fix1{
    font-size: 12px;
    width: 335px;
    padding: 0!important
  }
  .tcbi_image{
    width:292px
  }
  /* .body-root {
    width: 390px;
  } */
  .body-root {
    width: auto;
  }
  .navbar-dark .navbar-nav .nav-link span {
    text-align: center;
  }
  .yellow-mutants {
    position: absolute;
    color: #fba90a;
    top: 27%;
    left: 7px;
    transform: rotate(-8deg);
  }
  .news-letter-input::placeholder {
    font-size: 16px;
    line-height: 28px;
  }
  .subscribe-btn-container {
    justify-content: center !important;
    margin-top: 10px !important;
  }
  .news-letter-input {
    width: 100%;
  }
  .subscribe-text {
    font-size: 20px;
    font-weight: bold;
  }
  .gominii-logo {
    margin: 0.6rem 0.1rem 0.7rem 2rem;
    width: 140px;
    height: 39px;
    margin-left: 0;
  }
  .dcoin-img-footer {
    max-width: 12rem;
  }
  .dcoin-img-timer {
    max-width: 14rem;
  }
  .second-row-images {
    height: auto;
  }
  .bold-big-text {
    font-size: 21px;
    line-height: 24px;
    margin-top: 5px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .discord-community-text {
    padding-left: 0 !important;
  }

  .small-center {
    text-align: center;
  }
  .small-text {
    font-size: 0.7rem;
  }
  .mobile-none {
    display: none;
  }
  .nav-link span {
    display: block;
    text-align: left;
    font-size: 0.7rem;
  }

  .minii-image-text {
    height: 22px;
    width: 45.09px;
    margin-right: 5px;
    margin-left: 5px;
  }
  /* exp stands for explanation */
  .exp-text {
    display: block;
    font-size: 12px;
    /* text-align: justify; */
    color: #fafafa;
    font-family: "Roboto";
    /* padding-left: 20px;
    padding-right: 20px; */
    line-height: 1.8;
  }
  
  .bracket-title {
    padding-left: 20px;
    padding-right: 20px;
    
  }
  .bt_fix{
    height:5px;
  }
  .black-background {
    background: black;
  }
  .l-r-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .metallic-background-toggle {
    background: url("../../assets/images/landing/metallicBlackBackground.png")
      no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .metallic-background-toggle-reverse {
    
    background: black;
    border-top: 1px white solid;
  }
  .small-black-background {
    background-color: black;
  }
}
.splitter {
  background-color: #d0d0d0;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.btn-create-minii {
  background-color: #067ed5;
  color: white;
  padding: 10px;
  text-decoration: none;
  border-radius: 5px;
  width: 205px;
  height: 49px;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
}

.download-apple {
  width: 162px;
  height: 56px;
}
.social-icons img {
  display: inline;
  margin-left: 3px;
  margin-right: 3px;
}

.btn-create-minii:hover {
  cursor: pointer;
}

.badge-holder {
  position: absolute;
  bottom: 2rem;
  left: 4%;
}

.badge-holder-sm {
  position: absolute;
  right: 0;
}
.badge-holder-sm image {
  display: block;
}
.time-remaining {
  color: #4cadf3;
  font-weight: bold;
}

.video-cover {
  width: 100%;
  max-height: calc(100% - 18%);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.video-cover video {
  margin: 0;
  position: relative;
  width: 100vw;
  height: 90vh;
  background: #151515;
}
.content-section .container {
  max-width: 1000px;
}
.xcol {
  text-align: center;
  padding: 25px 0;
}
.xcol-center {
  text-align: center;
  padding-top: 110px;
  padding-bottom: 50px;
}
.xcol-center-sub {
  min-width: 550px;
  text-align: center;
  padding: 40px 0;
}
.xcol-center h2 {
  text-align: center;
  color: #090909;
  font-family: "Poppins", Sans-serif;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
}
.xcol-center h1 {
  text-align: center;
  color: #100a0a;
  font-family: "Poppins", Sans-serif;
  font-size: 39px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2em;
}
.xcol-center img {
  margin: 30px auto 40px auto;
}
.xcol > img {
  max-width: 320px;
}
.content-section-bottom .container {
  margin-top: 15px;
  margin-bottom: 70px;
}
.content-section-bottom .xcol-center-sub h2 {
  text-align: center;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 33px;
  font-weight: 800;
}
.content-section-bottom .xcol-center-sub .form {
  width: 100%;
  padding: 10px 10%;
}
.content-section-bottom .xcol-center-sub .form .textbox {
  background-color: #ffffff;
  border-width: 1px 1px 1px 1px;
  border-radius: 4px 4px 4px 4px;
  min-height: 47px;
  padding: 6px 16px;
  max-width: 100%;
  border: 1px solid #818a91;
  border-color: #f2f2f2;
  width: 45%;
}
.content-section-bottom .xcol-center-sub .form .textbox:focus {
  border: 2px solid #e4e5e7;
  outline: 0;
}
.content-section-bottom .xcol-center-sub .form .button {
  background-color: #030303;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  flex-basis: 100%;
  min-height: 47px;
  border-radius: 0px 0px 0px 0px;
  padding: 10px 30px 10px 30px;
  width: 100%;
  text-align: center;
  align-items: center;
  align-content: center;
  margin-top: 15px;
}
.mr-40 {
  margin-right: 10%;
}

.whitelist-section {
  display: flex;
  padding: 4rem 4rem 4rem 4rem;
  gap: 8rem;
}

.whitelist-section-logo {
  width: 346px;
  height: 381px;
  position: relative;
}

.whitelist-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.btn-join-whitelist {
  background-color: #067ed5;
  color: white;
  padding: 10px;
  text-decoration: none;
  border-radius: 5px;
  width: 207px;
  height: 56px;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
}

.mutants-of-the-miniiverse {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  position: absolute;
  color: #6EABE9;
  right: -180px;
  bottom: 0px;
  transform: rotate(-10deg);
  width: 180px;
  text-align: center;
}

.big-text {
  font-size: 48px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .nav-first-item {
  }
  .nav-second-item {
  }
  .nav-third-item {
  }
}
@media only screen and (max-width: 768px) {
  html,
  body {
    max-width: 100vw;
    overflow-x: hidden !important;
  }
  .bg-dark {
    height: auto;
  }
  .top-miniis {
    width: 100%;
    margin: 0;
  }
  .bracket-title {
    font-size: 14px;
  }
  .content-section-bottom .xcol-center-sub .form {
    width: 100vw;
    padding: 10px 10%;
  }
  input.textbox:nth-child(1) {
    width: 90vw !important;
  }
  input.textbox:nth-child(2) {
    width: 90vw !important;
  }
  .xcol-center-sub > h2:nth-child(1) {
    width: 100vw;
  }
  .video-cover > video:nth-child(1) {
    width: 100vw;
    height: 100vh;
  }
  .header-container-fluid {
    justify-content: space-evenly;
  }
  .video-cover {
    width: 100vw;
    height: 100vw;
  }
  .video-cover video {
    background: unset;
    margin-top: -237px;
  }
  .xcol-center {
    padding-top: 0;
    padding-bottom: 0;
  }
  #join {
    margin-top: -125px;
  }
  .btn_close {
    display: none;
  }
  .navbar-dark .navbar-nav .nav-link,
  .nav-item {
    text-align: left;
  }

  .whitelist-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    gap: 80px;
  }

  .whitelist-section-logo {
    width: 175px;
    height: 193px;
    position: relative;
  }

  .two-mutants-image {
    width: 100%;
    height: 100%;
  }

  .whitelist-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .whitelist-main-text {
    order: 2;
  }

  .btn-join-whitelist {
    background-color: #067ed5;
    color: white;
    padding: 10px;
    text-decoration: none;
    border-radius: 5px;
    width: 207px;
    height: 56px;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    order: 1;
  }

  .mutants-of-the-miniiverse {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 22px;
    position: absolute;
    color: #6EABE9;
    left: -80px;
    bottom: -40px;
    transform: rotate(-10deg);
    width: 100px;
    text-align: center;
  }

  .big-text {
    font-size: 21px;
    font-weight: 700;
  }

}
@media only screen and (max-width: 768px) {
  .btn_close {
    position: static;
    display: block;
    margin: 0 auto;
  }
  .with-text{
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}

