.text_fontsize_header{


font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 48px;
line-height: 64px;
margin-left: 2%;

color: #FAFAFA;
}


@media only screen and (max-width: 600px) {
   .text_fontsize_header{
    font-size: 21px;
    line-height: 24px;
    color: #FAFAFA;
   }
  }