
html, body {
    overflow: auto;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#renderCanvas {
    width: 100vw;
    height: 100vh;
    touch-action: none;
}
.test {
    margin:20px 5px;
    background-color:rgb(142, 142, 138);
    border: 1px solid #ccc;
    border-radius:10px;
    justify-content:center;
    display:flex;
    flex-wrap: wrap;
    min-height:100px

}
.image {

    width: 80px;
    height: 80px;
    border-radius: 5px;
    margin: 10px 5px;
    transition:all 0.3s ease;
    opacity:.7;
    cursor: pointer;
}
.image:hover {
    width: 90px;
    height: 90px;
    opacity: 1;

}
.btn {
    display: flex;
    align-items: center;
    margin:  auto 5px ;
    height: 60px;


}
@media only screen and (max-width:768px){
    .btn{
        margin:5px  ;
        justify-content:center;
        width:54%;
    }

}
