.max-container {
    max-width: 1000px;
}
.topLogo {
    width: 100%;
}
.part2-1 {
    margin-bottom: 30px;
}
.part2-1 .col h1 {
    color: #0C0C0C;
    font-family: "Poppins", Sans-serif;
    font-size: 88px;
    font-weight: 700;
    margin-top: 80px;
    margin-bottom: 25px;
}
.part2-1 .col .bold {
    color: #0A0A0A;
    font-size: 16px;
    text-decoration: none;
    line-height: 2.5em;
    letter-spacing: 0px;
    font-weight: bold;
}
.part2-1 .col .pbottom{
    color: #0A0A0A;
    font-size: 14px;
    text-decoration: none;
    line-height: 2.5em;
    letter-spacing: 0px;
    padding-bottom: 30px;
    font-weight: 400;
    border-bottom: solid #0C0C0C 1px;
}
.part3 {
    margin-bottom: 80px;
}
.part3 h3 {
    color: #0A0A0A;
    font-size: 16px;
    text-decoration: none;
    line-height: 2.5em;
    font-weight: bold;
}
.part3 p {
    color: #0A0A0A;
    font-size: 16px;
    text-decoration: none;
    line-height: 1.8em;
    font-weight: 400;
}

.part2-2 {
    text-align: center;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.part2-2 p{
    margin-top: 260px;
    text-align: center;
    color: #120101;
    font-size: 23px;
    font-weight: 500;
}