.top-text {
    padding-top: 60px;
    min-height: 150px;
    font-family: roboto;
    font-size: 16px;
    color: #fafafa;
    font-family: "Roboto";
}
.bottom-text {
    justify-content: "center";
    text-align: center;
}
.loading-screen-wrapper {
    background-color: black;
    width:100vw;
    height:100vh;
    position:absolute;
    text-align: -webkit-center;
}
.image-holder {
    max-width: 100%;
}
.image-holder img {
    max-width: 100%;
}
.inner-loading-wrapper {
    max-width: 390px;
}
.metallic-background {
    background: url("../../assets/images/landing/metallicBlackBackground.png")
      no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }