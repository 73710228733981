@import url('../config/colors.css');

body {
  font-family: "Poppins", sans-serif;
  color: var(--body-fg);
}
body::-webkit-scrollbar {
  width: 0.7em;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.container-fluid {
  padding: 0;
}
