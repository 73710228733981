@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
.root-contact-us {
  background-color: black;
  color: white;
  font-family: "Poppins";
}
.form-container {
  margin:0 auto;
  width:30%;
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.form-control::placeholder {
  color: #212020;

}
.contact_us_forom{
  font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 28px;



color: #FAFAFA;
}
.btn-send {
  background-color: #067ed5;
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
}
@media only screen and (max-width: 600px) {
  .form-container {
    width: 90%;
  }
}
.remove_hover:focus {
  box-shadow: initial;
  border: 1px solid #111;
}

.remove_hover {
  border-color: #818a91;
  height: 50px;
  width: 100%;
}

.send_button {
  background-color: black;
  color: white;
}

.send_button:focus {
  color: white;
  background-color: black;
}
