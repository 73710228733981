@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
.root-tos {
  background-color: black;
  color: white;
  font-family: "Poppins";
}

.h1_class {
  font-size: 39px;
  line-height: 1;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}
.h2_class {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6em;
  letter-spacing: unset;
  font-style: bold;
  margin-top: 20px;
}

.p_class {
  margin: 30px 7px 30px 7px;
}
