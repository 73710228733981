.demo-image-preview {
    width: 100vw;
    height: 100vh;
    position: absolute;
    overflow: hidden;
    text-align: center;
}

.demo-image-preview > img {
    height:100%;
    object-fit: cover;
}

@media(max-width:768px){
    .demo-image-preview > img {
        width: 100%;
    }
}

/* fullscreen enable by props */
.demo-image-preview-fullscreen > img {
    width: 100vw;
    height:100vh;
}
