.input_general{
    height:52px;
    width:90%;
    border: none;
    background: #F2F2F2;
    border-radius: 15px;
margin:10px 0;
    padding-left: 10px;
    color: #54555b;


}

.input_general:focus
 {
    outline: none;

}
.input_general::placeholder
	{
    color: #B5B7CA;
	}
