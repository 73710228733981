.footer-container-fluid {
  background-color: var(--footer-bg);
  min-height: 90px;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;

  align-items: center;
  align-content: center;
  justify-content: center;
}
.footer-social-icon {
  width: 45px;
}
.footer-container-fluid .container {
  max-width: 1000px;
}
.footer-drop-logo {
  width: 115px !important;
}
.footer-brand p {
  color: var(--footer-branding-color);
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
}

.footer-social-links {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}
.footer-social-links .links {
  width: 140px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.footer-social-links .links a {
  color: var(--footer-link-color);
  transition: 0.2s;
}

.footer-social-links .links a:hover {
  color: var(--footer-link-hover-color);
}
.privacy {
  display: flex;
  justify-content: center;
}
.privacy a {
  color: var(--footer-branding-color);
  text-decoration: none;
  padding: 10px;
  margin-top: -33px;
}

.social-icon-container {
  padding-right: 2rem;
}
@media only screen and (max-width: 600px) {
  .privacy a {
    margin: 0;
  }
  .social-icon-container {
    padding-right: 0;
  }
}
.test_test2{
  padding-left: 24px !important;
}
.test_test3{
  margin-bottom: -10px !important;
}
#test_1_test{
  margin-top: 10px !important;
}
