
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    position: relative;
    width: 300px;
    height: 256px;

    background: rgba(30, 29, 29, 0.85);
    border-radius: 5px;
    display: flex;
    flex-direction:column;
    justify-content:center ;
    align-items: center;

}

.close-icon {
    content: 'x';
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image_popup{
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 29px;
    color: #FFFFFF;
}
.button_popup{
    width:90%;
    height: 45px;
    background: #E31111;
    border-radius: 5px;
}
.Link_popup{
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: DM Sans ,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: center;

}
