.menu{
    background:url("/src/landing/assets/menu.png");
    width:40px;
    height:40px;
    border-radius: 8px;
    position: fixed;
    right:15px;
top:15px;
    cursor: pointer;
    z-index:10000;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.opacity_menu{
display:none !important;

}
.menu__lorger{

    width:100%;
    height:90vh;

    background: #FFFFFF;
    border-radius: 8px;
}
.fall{
    position: fixed;
    padding-top: 15px;
    display:flex;
    width: 100%;
    min-height: 80vh;
    align-items: center;
justify-content: space-around;
        flex-direction: column;
    background:white;
    font-family: Inter , sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    z-index:10000;


}
.fall a{
    text-decoration: none;
    color: black;
}
.fall span{
font-size: 40px;
font-weight:300;
    align-self: end;
    margin-right:15px;

}
.overview{
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 900;
    background-color: #4b4848;
    opacity: 0.9;
}
