
html, body {
    overflow: auto;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#renderCanvas1 {
    touch-action: none;
}

.test_three {
display:flex;

    flex-direction: column;
    width: 375px;
    height: 102px;

    background: #FFFFFF;
    box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.03);
    border-radius: 20px 20px 0px 0px;

    position:relative;
}
.plus_icon{
    position: absolute;
    top: -44px;
    left: 10px;
    font-size: 30px;
    background-color: white;
    font-weight: 300;
    border-radius:51%;
    width:30px;
height:30px;
    display: flex;
    justify-content:center;
    align-items: center;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
    /*padding-top:5px;*/
}

.slider_three{
    display:flex;
    justify-content:center;
    align-items: center;
    height:100%;

}
.options{
    justify-content: center;
    font-family: DM Sans , sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;

}
.image {

    width: 80px;
    height: 80px;
    border-radius: 5px;
    margin: 10px 5px;
    transition:all 0.3s ease;
    opacity:.7;
    cursor: pointer;
}
.image:hover {
    width: 90px;
    height: 90px;
    opacity: 1;

}
.btn {
    display: flex;
    align-items: center;
    margin:  auto 5px ;
    height: 60px;


}
.border_for_chooze_paernt{
    position:relative;
    width:100vw;
    justify-content:center;
    display: flex;
}
.border_for_chooze{
    position:absolute;

    border: 1px solid black;
    width: 70px;
    height: 70px;
    border-radius: 50%;

    bottom: 11px;

}

.arrow_camera{

    position: absolute;
    width: 37px;
    height: 37px;
    left: 14px;
    top: 14px;
    z-index: 100;
    background: #FFFFFF;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}
.arrow_camera img{
    width:15px;
    height: 13px;
}
.text_header_3dshow{
    margin-bottom: unset;
    width: 100vw;
    height: 70px;

display: flex;
    justify-content:center;
    align-items: center;
    font-family: DM Sans ,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 110%;

    /* or 26px */

    color: #0D0D0D;
}
@media only screen and (max-width:768px){
    .btn{
        margin:5px  ;
        justify-content:center;
        width:54%;
    }

}
