.login_body {
    /*height:100vh;*/
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


.login--image {
    margin-top: 20%;
    min-width: 225px;
    height: 172px;
    background: url("/src/landing/assets/minimarket.png") no-repeat;
    background-size: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.h1__login {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #27273B;
    margin-top: 36px;
}

.h2__login {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    width: 90%;
    margin-top: 13px;
    margin-left: 5%;
    display: flex;
    align-items: center;
    text-align: center;

    color: #60637F;
}

.staff {
    display: flex;
    position: relative;
    width: 90%;
    justify-content: space-between;
    position: relative;
}

.link_forgot {
    text-decoration: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    /* identical to box height, or 150% */


    color: #02137E;
}

.staff img {
    background-color: #f7f9fa;
    position: absolute;
    background-size: auto;
    right: 3%;
    top: -40px;
    cursor: pointer;
}

.button__extra--login {
    width: 90%;
    border-radius: 10px;
}

.text__cteate {

    font-family: DM Sans, Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;

    /* identical to box height, or 18px */

    color: rgba(0, 0, 0, 0.82);
}

.text__cteate a {
    text-decoration: none;
    color: black;
}
