.btn_cls_camera{
    font-family: "Montserrat", Sans-serif;
    position:absolute;
    left:10px;
    bottom:30px;
    width: 100px;
    height: 50px;
    font-size: 12px;
    font-weight: 700;
}
.extra_css{
    left:unset;
    right:10px;
    color:white;
}
.position{
    position:absolute;
    top:10px;
    left:40vw;
}

.opacity{
    display:none;
}
.btn_close {
    width: 100px;
    height: 50px;
    margin: 5px auto;
    color: white;
}
#outer-circle {
    left:-37px;

    z-index:1;
    bottom: 10px;
    width: 83px;
    height: 84px;
    /*left: 161px;*/
    /*top: 673px;*/

    background: #FF0000;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
}
#inner-circle{
    display:none;
}
.arrow_camera{

    position: absolute;
    width: 37px;
    height: 37px;
    left: 14px;
    top: 14px;
    z-index: 100;
    background: #FFFFFF;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
    display: flex;
border-radius: 50%;
    justify-content: center;
    align-items: center;
}
.arrow_camera img{
    width:15px;
    height: 13px;
}
.text__camera{

    font-family: DM Sans ,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 110%;

   z-index:3;
    position: absolute;
    color: #FFFFFF;
    width: 100vw;
    bottom: 166px;
    display: flex;
    justify-content: center;
}
.taked_pic--modifer{
    position: absolute;
    width: 143px;
    height: 44px;

    top: 76vh;

    background: #000000;
    border-radius: 10px;


    font-family: Inter , sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;

    /* or 207% */
    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFFFFF;
}

.wait_snippet{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.text__wait{
    font-family: Inter,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;

    text-align: center;

}

.take-photo {
    overflow:hidden;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.react-html5-camera-photo-fullscreen > video {
    object-fit: cover;
}

.face-tracker-overlay {
    position:absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.face-tracker-overlay img {
    object-position: top center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}

.rotate {
    animation: rotation 1.3s;
}

.linear {
    animation-timing-function: linear;
}

.infinite {
    animation-iteration-count: infinite;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

